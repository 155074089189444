import * as React from "react"
import { Link } from "gatsby"

import Layout from '../components/Showcase/layout';
import Seo from '../components/Showcase/seo';
import { StaticImage } from "gatsby-plugin-image"
import {ParallaxProvider, Parallax } from "react-scroll-parallax"


//* Fase 2: Add on-page navigation functionality 
// const handleClick = value => () => {
//   console.log(value)
//   const section = document.querySelector(`${value}`);
//   // console.log(section.id)
//   section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
// }

const SecondPage = () => (
  <ParallaxProvider>

  <Layout>
    <Seo title="Discovery & Planning" />
    <section className="intro-section">
      <span className="tag-line">Project Plan | Foundational Strategy | Technology & Platform Plan</span>
      <h1>Discovery & Planning</h1>
      <p>Every institution has unique needs and aspirations. To ensure that each partnership is successful, we spend the first phase understanding the current state of the web ecosystem — and building <strong>a Foundational Strategy that serves as a road map</strong> for the site’s transformation.</p>
    </section>
    <section className="banner">
      
      <Parallax translateY={["50", "-30"]}>
     
      <StaticImage
        src="../assets/images/discovery-and-planning/critical-users-interactions.png"
        width={2200}
        quality={100}
        placeholder="blurred"
         formats={["auto", "webp", "avif"]}
        alt="Critical Users Interactions"
        />
      </Parallax>
      <figcaption>Critical Users Interactions</figcaption>
    </section>
    <section className="portfolio">
      <p className="intro">Kick-Off Session - Stakeholder & End-User Interviews  - Institutional Goal Review - Technology & Functionality Audit - UX Discovery & Workshop - SEO Goals - Analytics Audit - Creative Aspirations</p>


      <div className="portfolio-card">
        <figure>
        <StaticImage
        src="../assets/images/discovery-and-planning/stakeholder-interviews.jpg"
        width={1280}
        quality={100}
        placeholder="blurred"
         formats={["auto", "webp", "avif"]}
        alt="Stakeholder Interviews"
        />
        
        </figure>
        <figcaption>Stakeholder Interviews</figcaption>
      </div>

      <div className="cols">
        <div className="portfolio-card col-50">
          <figure>
          <StaticImage
          src="../assets/images/discovery-and-planning/seo-audit.jpg"
          width={1280}
          quality={100}
          placeholder="blurred"
          formats={["auto", "webp", "avif"]}
          alt="SEO Audit"
          />
          
          </figure>
          <figcaption>SEO Audit</figcaption>
        </div>
        <div className="portfolio-card parallax col-50">
      <Parallax translateY={["50", "-30"]}>
      <figure>
        <StaticImage
        src="../assets/images/discovery-and-planning/technical-audit.jpg"
        width={1280}
        quality={100}
        placeholder="blurred"
         formats={["auto", "webp", "avif"]}
        alt="Technical Audit"
        />
        
        </figure>
        </Parallax>
        <figcaption>Technical Audit</figcaption>
      </div>
      </div>

      <div className="portfolio-card">
        <figure>
        <StaticImage
        src="../assets/images/discovery-and-planning/challenges.jpg"
        width={1280}
        quality={100}
        placeholder="blurred"
         formats={["auto", "webp", "avif"]}
        alt="Challenges"
        />
        
        </figure>
        <figcaption>Challenges</figcaption>
      </div>

      <div className="portfolio-card parallax">
      <Parallax translateY={["50", "-30"]}>
      <figure>
        <StaticImage
        src="../assets/images/discovery-and-planning/current-process.jpg"
        width={1280}
        quality={100}
        placeholder="blurred"
         formats={["auto", "webp", "avif"]}
        alt="Current Process"
        />
        </figure>
        </Parallax>
        <figcaption>Current Process</figcaption>
      </div>

      <div className="portfolio-card">
        <figure>
        <StaticImage
        src="../assets/images/discovery-and-planning/user-flows-and-audience-goals.jpg"
        width={1280}
        quality={100}
        placeholder="blurred"
         formats={["auto", "webp", "avif"]}
        alt="User Flows and Audience Goals"
        />
        
        </figure>
        <figcaption>User Flows and Audience Goals</figcaption>
      </div>

      <div className="portfolio-card parallax">
      <Parallax translateY={["50", "-30"]}>
      <figure>
        <StaticImage
        src="../assets/images/discovery-and-planning/creative-aspirations.jpg"
        width={1280}
        quality={100}
        placeholder="blurred"
         formats={["auto", "webp", "avif"]}
        alt="Creative Aspirations"
        />
        </figure>
        </Parallax>
        <figcaption>Creative Aspirations</figcaption>
      </div>
    </section>


    <section className="wrapper">
    <div className="section-title side-title">
        <h2>MORE DESIGN STEPS</h2>
        </div>
    <ul className="content-cards-wrapper">
          <li className="content-card">
            <Link to="/information-architecture">
              <StaticImage
                src="../assets/images/information-architecture-navigation-and-global-content-strategy.jpg"
                width={700}
                quality={90}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Discovery and Planning"
              />
              <div className="text-container">
                <h3>Information Architecture, Navigation & Global Content Strategy</h3>
                <p>Content Audit | Information Architecture | Global Strategy</p>
              </div>
            </Link>
          </li>
          <li className="content-card">
            <Link to="/ux-design">
              <StaticImage
                src="../assets/images/ux-design-wireframes-prototyping-and-user-testing.jpg"
                width={700}
                quality={90}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Discovery and Planning"
              />
              <div className="text-container">
                <h3>UX Design: Wireframes, Prototyping & User Testing</h3>
                <p>Page Wireframes Development | Responsive Planning</p>
              </div>
            </Link>
          </li>
          </ul>
    </section>
    
  </Layout>
  </ParallaxProvider>
)

export default SecondPage
